// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auftrag-js": () => import("./../../../src/pages/auftrag.js" /* webpackChunkName: "component---src-pages-auftrag-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-energieausweis-js": () => import("./../../../src/pages/leistungen/energieausweis.js" /* webpackChunkName: "component---src-pages-leistungen-energieausweis-js" */),
  "component---src-pages-leistungen-expose-js": () => import("./../../../src/pages/leistungen/expose.js" /* webpackChunkName: "component---src-pages-leistungen-expose-js" */),
  "component---src-pages-leistungen-grundriss-js": () => import("./../../../src/pages/leistungen/grundriss.js" /* webpackChunkName: "component---src-pages-leistungen-grundriss-js" */),
  "component---src-pages-leistungen-immobilienbewertung-js": () => import("./../../../src/pages/leistungen/immobilienbewertung.js" /* webpackChunkName: "component---src-pages-leistungen-immobilienbewertung-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-leistungen-vermarktung-js": () => import("./../../../src/pages/leistungen/vermarktung.js" /* webpackChunkName: "component---src-pages-leistungen-vermarktung-js" */),
  "component---src-pages-leistungen-wertermittlung-js": () => import("./../../../src/pages/leistungen/wertermittlung.js" /* webpackChunkName: "component---src-pages-leistungen-wertermittlung-js" */),
  "component---src-pages-nachricht-gesendet-js": () => import("./../../../src/pages/nachricht-gesendet.js" /* webpackChunkName: "component---src-pages-nachricht-gesendet-js" */)
}

